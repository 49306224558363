import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import yaml from "yaml";
import { saveAs } from "file-saver";
import axios from "axios";
import Quiz from "./quiz";
import Score from "./score";
import { parse } from "path";
// simple form using semantic ui react

const Players = [
    "Luna",
    "Emiel",
    "Pelle",
    "Kamiel",
    "Nathan",
    "Mona",
    "Kaat",
    "Zita",
    "Jorne",
];

interface Quiz {
    questions: Question[];
    solutions: string[];
}

interface Question {
    question: string;
    answers: string[];
}

interface AnswerSheet {
    name: string;
    date: string;
    time: number;
    answers: string[];
}

interface Answer {
    question: string;
    guess: string;
    solution: string;
}

// const quizex: Quiz = {answers:[""], questions: [{ question: "What is the capital of France?", answers: ["Paris", "London", "Berlin"]}, { question: "Who painted the Mona Lisa?", answers: ["Leonardo da Vinci", "Vincent van Gogh", "Pablo Picasso", "Claude Monet"] }, { question: "Which planet is known as the Red Planet?", answers: ["Mars", "Jupiter", "Venus", "Mercury", "Saturn", "Earth", "Uranus", "Neptune"] }, { question: "Who wrote the novel 'Pride and Prejudice'?", answers: ["Jane Austen", "Charlotte Brontë", "Emily Brontë", "William Shakespeare", "Charles Dickens", "F. Scott Fitzgerald"]}, { question: "What is the largest ocean in the world?", answers: ["Pacific Ocean", "Atlantic Ocean", "Indian Ocean", "Southern Ocean", "Arctic Ocean", "Antarctic Ocean"]}] };

const App = () => {
    const [state, setState] = React.useState(0);
    const [name, setName] = React.useState("");
    const [quiz, setQuiz] = React.useState<Quiz>({} as Quiz);
    const [guesses, setGuesses] = useState<Map<number, string>>(
        new Map<number, string>()
    );
    const [screenname, setscreenname] = React.useState("");
    const [timer, setTimer] = useState(0);
    const [isRunning, setIsRunning] = useState(false);

    useEffect(() => {
        let interval: number = 0;
        if (isRunning) {
            interval = window.setInterval(() => {
                setTimer((prevTimer) => prevTimer + 1);
            }, 1000);
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [isRunning]);

    const startTimer = () => {
        setIsRunning(true);
    };

    const stopTimer = () => {
        setIsRunning(false);
    };

    const uploadYaml = async (yamlString: string) => {
        try {
            const response = await fetch("http://127.0.0.1:5000/upload", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-yaml",
                },
                body: yamlString,
            });

            if (response.ok) {
                console.log("Upload successful!", response);
                // Handle the response as needed
            } else {
                console.error(
                    "Error uploading YAML:",
                    response.status,
                    response.statusText
                );
                // Handle the error as needed
            }
        } catch (error) {
            console.error("Error uploading YAML:", error);
            // Handle the error as needed
        }
    };
    const shuffle = (array: string[]) => {
        const shuffledArray = array.slice(); // Create a copy to avoid modifying the original array

        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const randomIndex = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[randomIndex]] = [
                shuffledArray[randomIndex],
                shuffledArray[i],
            ];
        }
        return shuffledArray;
    };
    const shuffleAnswers = (parsedData: Question[]) => {
        const updatedParsedData = parsedData.map((question) => ({
            ...question,
            answers: shuffle(question.answers),
        }));
        return updatedParsedData;
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("quiz/quiz.yaml");
                const yamlData = await response.text();
                const parsedData = yaml.parse(yamlData) as Quiz;
                // parsedData.questions = shuffleAnswers(parsedData.questions);
                setQuiz(parsedData);
                // Process the parsedData as needed
            } catch (error) {
                console.error("Error:", error);
            }
        };

        fetchData();
    }, []);

    switch (state) {
        default:
            return (
                <div
                    style={{
                        position: "absolute",
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                        alignSelf: "center",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "black",
                    }}
                >
                    <div
                        className="lg:w-1/2"
                        style={{
                            textAlign: "center",
                            height: "100%",
                            paddingTop: 100,
                        }}
                    >
                        <img src="mol.png" style={{ width: "100%" }} />
                        <h1 className="text-white text-4xl" style={{marginBottom: 20 }}>
                            Wie is de mol?
                        </h1>
                        <h3 style={{ color: "#0B5345", fontSize: 36, marginBottom: -20 }}>
                            DOZA
                        </h3>
                        <h3 style={{ color: "#0B5345", fontSize: 36, marginBottom: -20 }}>
                            Teamdag
                        </h3>
                        <h3 style={{ color: "#0B5345", fontSize: 36, marginBottom: -20 }}>
                            2024
                        </h3>
                        <div style={{ height: "20%" }}></div>
                        <div style={{ display: "flex", alignContent: "center" }}>
                            <Form style={{ flex: 1 }}>
                                <Form.Field>
                                    {/*<label style={{color:'white'}}>Naam?</label>*/}
                                    <Input
                                        autoFocus
                                        style={{
                                            width: "60%",
                                            color: "#004D40",
                                            inputColor: "#004D40",
                                        }}
                                        size="huge"
                                        placeholder="Wat is je naam?"
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Form.Field>
                                <Button
                                    size="huge"
                                    style={{
                                        backgroundColor: "#004D40",
                                        color: "black",
                                        width: "60%",
                                    }}
                                    type="submit"
                                    onClick={() => {
                                        console.log("yes");
                                        if (Players.includes(name) || name.length > 1) {
                                            startTimer();
                                            setState(1);
                                        // } else if (name === "screen") {
                                        //     setState(2);
                                        // } else if (name === "elim") {
                                        //     setState(3);
                                        } else {
                                            setState(0);
                                        }
                                    }}
                                >
                                    Start de test
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            );
        case 1:
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "black",
                    }}
                >
                    <div
                        style={{
                            textAlign: "left",
                            flex: 0.9,
                        }}
                        className="lg:pt-40 lg:mb-40 pt-10 mb-10"
                    >
                        <h1
                            className="lg:mb-40 mb-10"
                            style={{
                                textAlign: "center",
                                color: "white",
                            }}
                        >
                            Wie is de mol?
                        </h1>
                        <Form>
                            {quiz.questions.map((question, index) => (
                                <Quizvraag
                                    index={index}
                                    quiz={quiz}
                                    guesses={guesses}
                                    setGuesses={setGuesses}
                                />
                            ))}
                        </Form>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                size="huge"
                                style={{
                                    backgroundColor: "#004D40",
                                    color: "black",
                                    width: "50%",
                                }}
                                type="submit"
                                onClick={() => {
                                    stopTimer();
                                    console.log(guesses, name);

                                    const answers: AnswerSheet = {
                                        name: name,
                                        date: Date().toString(),
                                        time: timer,
                                        answers: quiz.questions.map((_, index) => guesses.get(index) || "")
                                    };
                                    // uploadYaml(yaml.stringify(answers))
                                    axios.post('https://mol-clone.onrender.com/answer/-O0QH6QPabiKx1p6BMI8', answers)
                                        .then(function(response) {
                                            console.log("yes", response);
                                        })
                                        .catch(function(error) {
                                            console.log(error);
                                        });
                                    ;
                                    setName("")
                                    setGuesses(new Map<number, string>())

                                    setState(0)
                                    setTimer(0)
                                    setIsRunning(false)

                                }}
                            >
                                Dien in
                            </Button>
                            <div
                                style={{ textAlign: "center", fontSize: 48, color: "#004D40" }}
                            >
                                {timer}
                            </div>
                        </div>
                    </div>
                </div>
            );
        case 2:
            return <div></div>;
        case 3:
            return <Elim func={setscreenname} func2={setState} />;
        case 4:
            return <Screen name={screenname} func={setState} />;
    }
};

function Quizvraag({
    index,
    quiz,
    guesses,
    setGuesses,
}: {
    index: number;
    quiz: Quiz;
    guesses: Map<number, string>;
    setGuesses: Function;
}) {
    const question = quiz.questions[index];

    const handleAnswerChange = (answer: string) => {
        setGuesses(new Map(guesses.set(index, answer)));
    };

    return (
        <div key={index}>
            <label className="text-2xl lg:text-xl pt-10" style={{ color: "white" }}>
                {index+1}. {question.question}
            </label>
            <div
                className="lg:mt-20 lg:mb-30"
                style={{
                    alignContent: "center",
                    display: "flex",
                    flexWrap: "wrap",
                }}
            >
                {question.answers.map((answer, i) => (
                    <div
                        key={i}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: 30,
                            paddingBottom: 20,
                        }}
                    >
                        <label
                            style={{
                                color: guesses.get(index) === answer ? "#0E6655" : "white",
                                marginRight: 10,
                                fontSize: 16,
                                whiteSpace: "nowrap",
                            }}
                        >
                            {answer}
                        </label>
                        <Form.Radio
                            style={{ marginBottom: -2 }}
                            label=""
                            value={answer}
                            checked={guesses.get(index) === answer}
                            onChange={() => handleAnswerChange(answer)}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

function Elim({ func, func2 }: { func: Function; func2: Function }) {
    const [name, setName] = React.useState("");
    return (
        <div
            style={{
                position: "absolute",
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                alignSelf: "center",
                width: "100%",
                height: "100%",
                backgroundColor: "black",
            }}
        >
            <div
                style={{
                    textAlign: "center",
                    width: "50%",
                    height: "100%",
                    paddingTop: 100,
                }}
            >
                <div style={{ flex: 1, justifyContent: "right" }}>
                    <img src="ksamolvierkant.png" style={{ width: "40%" }} />
                </div>
                <div></div>
                {/* <h1 style={{ color: "white", fontSize: 56, marginBottom: 20 }}>
          Wie is de mol?
        </h1> */}
                {/* <h3 style={{ color: "#0B5345", fontSize: 36, marginBottom: -20 }}>
          Hernieuwers
        </h3>
        <h3 style={{ color: "#0B5345", fontSize: 36, marginBottom: -20 }}>
          Kamp
        </h3>
        <h3 style={{ color: "#0B5345", fontSize: 36, marginBottom: -20 }}>
          2023
        </h3> */}
                <div style={{ height: "20%" }}></div>
                <div style={{ display: "flex", alignContent: "center" }}>
                    <Form style={{ flex: 1 }}>
                        <Form.Field>
                            {/*<label style={{color:'white'}}>Naam?</label>*/}
                            <Input
                                autoFocus
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "#004D40",
                                    inputColor: "#004D40",
                                }}
                                size="huge"
                                placeholder="Naam?"
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Form.Field>
                        <Button
                            size="huge"
                            style={{
                                backgroundColor: "black",
                                color: "black",
                                width: "60%",
                            }}
                            type="submit"
                            onClick={() => {
                                if (Players.includes(name)) {
                                    func(name);
                                    func2(4);
                                }
                            }}
                        ></Button>
                    </Form>
                </div>
            </div>
        </div>
    );
}

function Screen({ name, func }: { name: string; func: Function }) {
    const handleKeyDown = (event: KeyboardEvent) => {
        const { key } = event;
        console.log(3);
        func(3);
    };
    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);
    return (
        <div
            style={{
                position: "absolute",
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                alignSelf: "center",
                width: "100%",
                height: "100%",
                backgroundColor: "black",
            }}
        >
            {name === "Pelle" ? (
                <img src="ksamolvierkantrood.png" style={{ width: "60%" }} />
            ) : (
                <img src="ksamolvierkant.png" style={{ width: "60%" }} />
            )}
            {/* <h1 style={{ color: "white", fontSize: 56, marginBottom: 20 }}>
          Wie is de mol?
        </h1> */}
            {/* <h3 style={{ color: "#0B5345", fontSize: 36, marginBottom: -20 }}>
          Hernieuwers
        </h3>
        <h3 style={{ color: "#0B5345", fontSize: 36, marginBottom: -20 }}>
          Kamp
        </h3>
        <h3 style={{ color: "#0B5345", fontSize: 36, marginBottom: -20 }}>
          2023
        </h3> */}
        </div>
    );
}

<div style={{ backgroundColor: "black" }}></div>;
export default App;
